export default {
  message: {
    hello: 'Hello',
    welcome: 'Welcome'
  },
  lottery: {
    title: 'Lottery Hall',
    period: 'Period',
    balance: 'Balance',
    currency: 'CNY',
    tips: {
      playTip: 'Play Tips',
      gameRules: 'Game Rules',
      gameTip: 'Game Tips',
      winDesc: 'Win Description',
      betExample: 'Bet Example',
      tipContent: 'Select numbers in option and value form to bet',
      winContent: 'Sum of three winning numbers 11-18 is big, sum 3-10 is small',
      exampleContent: 'Bet plan: Small, winning numbers: 123, wins small'
    },
    betting: {
      orderList: 'Order List',
      balance: 'Balance',
      submit: 'Submit',
      currentSelect: 'Current Selection',
      notSelected: 'Not Selected',
      perAmount: 'Amount per bet',
      enterAmount: 'Please enter amount',
      total: 'Total',
      bet: 'Bet',
      amount: 'Amount'
    },
    order: {
      title: 'Bet Order',
      detail: '{bet} bets x {amount} CNY = {total} CNY',
      clearOrder: 'Clear Order',
      confirmSubmit: 'Confirm Submit'
    },
    toast: {
      amountError: 'Amount Error!',
      selectNumber: 'Please select numbers!',
      enterAmount: 'Please enter amount!',
      balanceInsufficient: 'Insufficient balance, please contact customer service to recharge!',
      winningNumber: 'Draw successful, period: {expect}',
      contactAdmin: 'Please contact admin for this round!',
      refreshSuccess: 'Refresh successful'
    },
    refresh: {
      loading: 'Loading...',
      pulling: 'Pull down to refresh...',
      loosing: 'Release to refresh'
    },
    history: {
      period: 'Period',
      result: 'Winning Numbers',
      type: {
        sexy: 'Sexy',
        sweet: 'Sweet',
        mature: 'Mature',
        pure: 'Pure'
      }
    },
    types: {
      "섹시": "Sexy",
      "스윗": "Sweet",
      "성숙": "Mature",
      "청순": "Pure"
    }
  },
  login: {
    title: 'Login',
    username: 'Please enter username',
    password: 'Please enter password',
    forgotPassword: 'Forgot password?',
    noAccount: 'No account? Register now',
    loginButton: 'Login',
    usernameRequired: 'Please enter username!',
    passwordRequired: 'Please enter password!'
  },
  register: {
    title: 'Register',
    username: 'Username (6-12 letters or numbers)',
    password: 'Password (6-12 letters or numbers)',
    inviteCode: 'Please enter invite code',
    agreement: 'I have read and agree to the terms of the account opening agreement',
    registerButton: 'Register',
    inviteRequired: 'Please enter invite code!',
    agreementRequired: 'Please check the agreement below!'
  },
  home: {
    hotGame: {
      title: 'Recommended Games',
      more: 'View More'
    },
    hotRecommend: {
      rankTitle: 'Hot Ranking',
      recommendTitle: 'Hot Recommended',
      more: 'View More',
      playCount: 'Plays:'
    },
    refresh: {
      loading: 'Loading...',
      pulling: 'Pull to refresh...',
      loosing: 'Release to refresh'
    },
    toast: {
      refreshSuccess: 'Refresh successful'
    }
  },
  game: {
    title: 'Game Hall',
    sidebar: {
      all: 'All'
    },
    refresh: {
      loading: 'Loading...',
      pulling: 'Pull to refresh...',
      loosing: 'Release to refresh'
    },
    toast: {
      refreshSuccess: 'Refresh successful'
    }
  },
  choose: {
    title: 'Beauty',
    tabs: {
      cityBattle: 'City Battle',
      processPrice: 'Process & Price'
    },
    cityDesc: 'The most authentic platform for business escort + city dating. To protect privacy, users can only register through referrals from our staff or senior members.',
    process: {
      title1: 'What resources do we have?',
      content1: 'Internet celebrities, models, flight attendants, fresh models, college students - we have everything you can imagine',
      title2: 'Service Coverage?',
      content2: 'Free local dating, nationwide travel to any location. Available in first and second-tier cities, third-tier cities can be booked through reception.',
      content3: 'The most authentic platform for business escort + city dating. To protect privacy, users can only register through referrals from our staff or senior members.'
    },
    list: {
      refresh: {
        loading: 'Loading...',
        pulling: 'Pull to refresh...',
        loosing: 'Release to refresh',
        success: 'Refresh successful!'
      }
    },
    profile: {
      book: 'Book',
      toast: 'Please contact reception or administrator'
    }
  },
  video: {
    title: 'Video Hall',
    list: {
      refresh: {
        loading: 'Loading...',
        pulling: 'Pull to refresh...',
        loosing: 'Release to refresh',
        success: 'Refresh successful',
        noMore: 'No more'
      },
      playCount: 'Plays:'
    },
    player: {
      playCount: 'plays',
      recommend: 'Hot Recommend',
      offline: 'Account offline',
      recharge: 'Please recharge to watch videos!',
      title: 'Video Player',
      back: 'Back'
    }
  },
  mine: {
    title: 'Profile',
    finance: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      rechargeHint: 'Please contact customer service to recharge'
    },
    service: {
      title: 'Online Service',
      contact: 'Contact',
      available: '24/7 Online Service',
      defaultName: 'Honey Badger Entertainment',
      disabled: 'Function disabled'
    },
    bank: {
      bind: {
        title: 'Bind Bank Card',
        toast: {
          setBankFirst: 'Please set bank card first'
        }
      }
    },
    wallet: {
      title: 'My Wallet',
      details: 'Details',
      balance: 'Balance'
    },
    menu: {
      report: 'Personal Report',
      account: 'Account Details',
      gameRecord: 'Game Records',
      profile: 'Profile Center',
      notice: 'Notifications',
      service: 'Customer Service',
      accountTip: 'Please complete task list before entering'
    },
    setting: {
      title: 'Settings Center',
      items: {
        profile: 'Profile',
        loginPwd: 'Login Password',
        payPwd: 'Payment Password'
      },
      status: {
        set: 'Set',
        notSet: 'Not Set'
      },
      button: {
        logout: 'Sign Out'
      },
      toast: {
        payPwdTip: 'Payment password has been set. Please contact customer service for modifications'
      }
    },
    gameRecord: {
      title: 'Game Records',
      // ...
    },
    loginHint: 'Login to enjoy more services!'
  },
  footer: {
    home: 'UP LOVE',
    game: 'Games',
    choose: 'Beauty',
    video: 'Video',
    mine: 'Mine'
  }
}