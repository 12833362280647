import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './langs/zh'
import en from './langs/en'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'en', // 默认语言为英文
  messages: {
    zh,
    en
  }
})

export default i18n 