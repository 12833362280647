<template>
  <div class="container page">
    <van-nav-bar :title="$t('mine.gameRecord.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh :loading-text="$t('mine.gameRecord.refresh.loading')" :pulling-text="$t('mine.gameRecord.refresh.pulling')" :loosing-text="$t('mine.gameRecord.refresh.loosing')" v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" :description="$t('mine.gameRecord.empty')" />
        <div v-else class="item_list" v-for="(v, key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <span class="period-number">{{ v.expect }}</span>
            <span class="period-number">{{ v.name }}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <span class="res-des middle">{{ v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
              <span class="res-des middle">{{ v.status === 0 ? 0 : (Number(v.opencode[0]) + Number(v.opencode[1]) + Number(v.opencode[2])) >= 11 ? $t('mine.gameRecord.list.sexy') : $t('mine.gameRecord.list.sweet') }}</span>
              <span class="res-des middle">{{ v.status === 0 ? 0 : (Number(v.opencode[0]) + Number(v.opencode[1]) + Number(v.opencode[2])) % 2 === 0 ? $t('mine.gameRecord.list.mature') : $t('mine.gameRecord.list.pure') }}</span>
            </div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{ v.status_text }}</span>
            <span v-else>{{ v.status_text }}</span>
            <span>{{ $t('mine.gameRecord.list.betAmount') }}：{{ v.money }}</span>
          </div>
          <div class="time">
            <span>{{ $t('mine.gameRecord.list.time') }}：{{ v.create_time }}</span>
          </div>
          <div class="time" style="display: flex;justify-content: space-between;align-items: center;">
            <span style="flex: 1;">{{ $t('mine.gameRecord.list.paymentTime') }}：{{ v.update_time }}</span>
            <span @click="goDetail(v)" class="desc-button" style="flex: none;">{{ $t('mine.gameRecord.list.details') }}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: []
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('mine.gameRecord.refresh.success'));
        this.getUserGameList();
        this.isLoading = false;
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res => {
        if(res.code === 200) {
          this.list = res.data;
        } else if(res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    goDetail(item) {
      this.$router.push({ path: '/recordDetail', query: { data: JSON.stringify(item.data) } });
    }
  },
  created() {
    if(!localStorage.getItem('token')) {
      this.$router.push({path:'/Login'})
    } else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}

.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}


.item_list .topInfo span:last-child {
  float: right;
}

.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

.item_list .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.item_list .period-number {
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.item_list .lottery_info {
  display: flex;
}

.recent {
  display: flex;
  align-items: center;
  height: 100px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}
.item_list .desc-button {
  padding: 0 4vw;
    margin: 0 4vw;
    margin-left: 2.667vw;
    color: #fff !important;
    background: linear-gradient(270deg, #e6c3a1, #7e5678);
    font-size: 5.333vw;
    font-weight: 500;
    height: 6.333vw;
    line-height: 6.333vw;
    border-radius: 6.667vw;
}
</style>
