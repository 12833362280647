<template>
  <div class="container page">
    <van-nav-bar :title="$t('lottery.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>

    <div class="header">
      <div class="avatar-frame">
        <van-image 
          class="avatar"
          :src="lottery.ico"
          round
        >
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
      </div>
      
      <!-- <div class="kuaisan-ball">
        <van-image class="res-img" :src="shanzi_1">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
        <van-image class="res-img" :src="shanzi_2">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
        <van-image class="res-img" :src="shanzi_3">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
      </div> -->
      
      <van-count-down 
        :time="time" 
        @finish="check()"
        format="mm:ss"
        class="countdown"
      >
        <template #default="timeData">
          <div class="time-block">{{ timeData.minutes.toString().padStart(2, '0') }}</div>
          <div class="time-separator">:</div>
          <div class="time-block">{{ timeData.seconds.toString().padStart(2, '0') }}</div>
        </template>
      </van-count-down>
    </div>

    <div class="period-bar">
      <span class="period-number">{{ lottery.next_expect }}</span>
      <div class="period-type">
        <span class="type-text" @click="active = true">{{ $t('lottery.period') }}</span>
      </div>
    </div>

    <div class="history_popup"></div>
    <div class="wrapper">
      <div class="options-bar">
        <div class="game">
          <div class="tips">
            <p class="odds">【{{ lottery.desc }}】</p>
            <div class="play-tip">
              <van-icon name="more-o" />
              <!--                  <span class="span-text" @click="playgame = true">玩法提示</span>-->
              <span class="span-text" @click="$router.push({ path: '/GameRecord' });">{{ $t('lottery.betting.orderList') }}</span>
              <van-popup class="mask" get-container="body" v-model="playgame">
                <div class="play-type-tip">
                  <div class="title">{{ $t('lottery.tips.gameRules') }}</div>
                  <div class="wrapper">
                    <div class="item">
                      <van-icon name="info-o" />
                      <div class="content">
                        <p class="content-title">{{ $t('lottery.tips.gameTip') }}</p>
                        <p class="content-detail">{{ $t('lottery.tips.tipContent') }}</p>
                      </div>
                    </div>
                    <div class="item">
                      <van-icon name="comment-o" />
                      <div class="content">
                        <p class="content-title">{{ $t('lottery.tips.winDesc') }}</p>
                        <p class="content-detail">{{ $t('lottery.tips.winContent') }}</p>
                      </div>
                    </div>
                    <div class="item">
                      <van-icon name="description" />
                      <div class="content">
                        <p class="content-title">{{ $t('lottery.tips.betExample') }}</p>
                        <p class="content-detail">{{ $t('lottery.tips.exampleContent') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </van-popup>
            </div>
          </div>
          <div class="linear-gradient"
            style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
          </div>
          <div class="sumValueTwoSides">
            <div class="rectangle large" :class="{ active: choose[v.type] }" v-for="(v, key) in lottery_peilv_list"
              :key="key" @click="choosePlay(v.type, v.name);">
              <div class="wrapper">
                <div class="content">
                  <p class="name-text large">{{v.name}}</p>
                  <!-- <p class="odd-text large">{{ v.proportion }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left">
            <div class="item" @click="shopping ? shopping = false : shopping = true">
              <van-icon name="cart-o" class="jixuanico" />
              <span class="text">{{ $t('lottery.betting.orderList') }}</span>
            </div>
            <div class="line"></div>
          </div>
          <div class="mid">
            <span class="text">{{ $t('lottery.balance') }}</span>
            <span class="text num">{{ showMoney?userInfo.money:'***' }}</span>
            <span class="text">{{ $t('lottery.currency') }}</span>
            <span @click="showMoney = !showMoney" style="padding-left: 4px;">
              <van-icon name="closed-eye" v-if="!showMoney"/>
              <van-icon name="eye-o" v-else />
             </span>
          </div>
          <div class="right" @click="jiesuan()">
            {{ $t('lottery.betting.submit') }}
          </div>
        </div>
        <div class="wrapper" :class="{ active: shopping }">
          <div class="item">
            <span class="label">{{ $t('lottery.betting.currentSelect') }}：</span>
            <div class="bet-number">{{ shopchoose }}</div>
            <van-icon name="arrow-down" :class="{ up: !shopping, down: shopping }"
              @click="shopping ? shopping = false : shopping = true" />
          </div>
          <div class="item">
            <span class="label">{{ $t('lottery.betting.perAmount') }}</span>
            <div class="amount-wrapper">
              <van-field v-model="money" type="digit" :placeholder="$t('lottery.betting.enterAmount')" style="font-size: 16px;" />
              <span class="label">{{ $t('lottery.currency') }}</span>
            </div>
          </div>
          <div class="item">
            <div class="part">
              <span>{{ $t('lottery.betting.total') }}</span>
              <span class="number">{{ formData.length }}</span>
              <span>{{ $t('lottery.betting.bet') }}</span>
            </div>
            <div class="part">
              <span>{{ $t('lottery.betting.total') }}</span>
              <span class="number">{{ formData.length * money }}</span>
              <span>{{ $t('lottery.currency') }}</span>
            </div>

          </div>
        </div>
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">{{ $t('lottery.order.title') }}</p>
          </div>
          <ui class="list">
            <li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
              <div class="main">
                <p class="bet-name">{{ v.name }}</p>
                <p class="detail-text">{{ $t('lottery.order.detail', { bet: 1, amount: money, total: money }) }}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </li>
          </ui>
          <div class="sub-bar">
            <van-button class="item cancel-btn" type="default" @click="allClear()">{{ $t('lottery.order.clearOrder') }}</van-button>
            <van-button class="item sub-btn" type="default" @click="doSub">{{ $t('lottery.order.confirmSubmit') }}</van-button>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="active" position="top" :style="{ height: '70%' }">
        <van-pull-refresh :loading-text="$t('lottery.refresh.loading')" :pulling-text="$t('lottery.refresh.pulling')" :loosing-text="$t('lottery.refresh.loosing')" v-model="isLoading" @refresh="onRefresh">
          <div class="wrapper">
            <div class="item">
              <div class="left font-weight">{{ $t('lottery.period') }}</div>
              <div class="right font-weight">{{ $t('lottery.history.result') }}</div>
            </div>
            <div class="item" v-for="(v, key) in lottery_list" :key="key">
              <div class="left font-weight">{{ v.expect }}</div>
              <div class="right font-weight">
                <div class="kuaisan-ball left">
                  <span class="res-des middle">{{ v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
                  <span class="res-des middle">{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t('lottery.history.type.sexy') : $t('lottery.history.type.sweet') }}</span>
                  <span class="res-des middle">{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t('lottery.history.type.pure') : $t('lottery.history.type.mature') }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-pull-refresh>
      </van-popup>
    </div>
  </div>
</template>

<script>
var time;
var count = 0;
export default {
  data() {
    return {
      jiesuanpage: false,
      showMoney:false,
      choose: {
        "大": false,
        "小": false,
        "单": false,
        "双": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
        "13": false,
        "14": false,
        "15": false,
        "16": false,
        "17": false,
        "18": false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: 0,
      shopchoose: this.$t('lottery.betting.notSelected'),
      gameitem: "",
      formData: [],
      money: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    doSub() {
      if (this.money === "0") {
        this.$toast(this.$t('lottery.toast.amountError'));
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast(this.$t('lottery.toast.selectNumber'));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t('lottery.toast.enterAmount'));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t('lottery.toast.balanceInsufficient'));
          return false;
        } else {
          this.$http({
            method: 'post',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
          })
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t('lottery.betting.notSelected');
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {
      if (this.formData.length === 0) {
        this.$toast(this.$t('lottery.toast.selectNumber'));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t('lottery.toast.enterAmount'));
        return false;
      }
      else {
        this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
      }

    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]['type']) {
          this.formData.splice(i, 1)
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t('lottery.betting.notSelected');
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({ 'name': name, 'type': type })
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t('lottery.betting.notSelected');
        this.gameitem = "";
        this.shopping = false;
      }

    },
    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        time = window.setInterval(() => {
          setTimeout(() => {
            this.getUserInfo();
            this.getLotteryInfo();
            this.getLotteryList();
            count++;
            if (count > 5) {
              clearInterval(time);
              count = 0;
            }
          }, 0)
        }, 300)
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("새로 고침 성공");
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'lottery_get_peilv'
      }).then(res => {
        console.log(999999, res);
        if (res.code === 200) {
          this.lottery_peilv_list = res.data.map(item => ({
            ...item,
            name: this.$t(`lottery.types.${item.name}`)
          }));
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryList() {
      this.$http({
        method: 'get',
        data: { key: this.$route.query.key },
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryInfo() {
      this.$http({
        method: 'get',
        data: { key: this.$route.query.key },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
            this.$toast("이 작업은 관리자에게 문의하십시오.!");
            this.$router.push({ path: '/Home' })
            return false;
          }
          console.log(res.data);
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast("당첨 성공, 기호：" + this.lottery.now_expect);
          }
          this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png";
          this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png";
          this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png";
          this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          if (this.sum >= 11 && this.sum <= 18) {
            this.size = "섹시";
          } else if (this.sum >= 3 && this.sum <= 10) {
            this.size = "스윗";
          }
          if (this.sum % 2 === 0) {
            this.double = "청순";
          } else {
            this.double = "성숙";
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })

    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();

    }
  },
  destroyed() {
    clearInterval(time);
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

// 定义主题色变量
@primary-color: #ff6b9d;
@primary-light: rgba(255, 107, 157, 0.1);
@primary-shadow: rgba(255, 107, 157, 0.15);
@text-primary: #333;
@text-secondary: #666;

.container {
  background: #f8f8f8;
  min-height: 100vh;
  padding-top: 0;
  
  .header {
    margin-top: 20px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  justify-content: space-evenly;
  .avatar-frame {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      background: @primary-color;
      border-radius: 50%;
      z-index: 0;
    }

    .avatar {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      border: 2px solid #fff;
    }
  }

  .countdown {
    display: flex;
    align-items: center;
    gap: 4px;

    .time-block {
      width: 120px;
      height: 120px;
      background: @primary-color;
      color: white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 65px;
      font-weight: bold;
    }
    
    .time-separator {
      color: @primary-color;
      font-size: 65px;
      font-weight: bold;
      margin: 0 -2px;
    }
  }

  .kuaisan-ball {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 20px;
    
    .res-img {
      width: 60px;
      height: 60px;
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }
  }
}

.period-bar {
  background: #fff2f6;
  margin: 20px 0;
  padding: 16px 30px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px @primary-shadow;
  position: relative;
  
  .period-number {
    color: @text-secondary;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .period-type {
    display: flex;
    align-items: center;
    gap: 15px;
    
    .history-btn {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      background: @primary-light;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.3s ease;
      
      .van-icon {
        font-size: 24px;
        color: @primary-color;
        margin-right: 6px;
      }
      
      span {
        color: @primary-color;
        font-size: 28px;
        font-weight: 500;
      }
      
      &:hover {
        background: rgba(255, 107, 157, 0.2);
      }
    }
    
    .type-text {
      color: @primary-color;
      font-size: 32px;
      font-weight: 600;
      padding: 8px 16px;
      background: @primary-light;
      border-radius: 20px;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border: 1px solid @primary-light;
        border-radius: 19px;
        pointer-events: none;
      }
    }
  }
}

.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
}

.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
}

.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: @primary-color;
}

.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}

::v-deep .van-icon-more-o {
  color: @primary-color;
  font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: @primary-color;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.sumValueTwoSides {
  display: flex;
  padding: 30px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  .rectangle {
    &.large {
      margin: 0 0 30px 0;
      width: 48%;
      height: 180px;
      background: #fff2f6;
      border-radius: 15px;
      box-shadow: 0 4px 8px @primary-shadow;
      transition: box-shadow 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        box-shadow: 0 6px 12px rgba(255, 107, 157, 0.25);
      }
      
      &.active {
        background: @primary-color !important;
        box-shadow: 0 6px 12px rgba(255, 107, 157, 0.3) !important;
      }
      
      .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        
        .content {
          text-align: center;
          
          .name-text {
            color: @primary-color;
            font-size: 48px;
            margin-bottom: 12px;
            font-weight: 500;
          }
          
          .odd-text {
            color: @primary-color;
            font-size: 32px;
            font-weight: 400;
          }
        }
      }
      
      &.active .wrapper {
        background: transparent !important;
        
        .name-text,
        .odd-text {
          color: #fff !important;
        }
      }
    }
  }
}

.bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  z-index: 2;

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: #fff;
    box-shadow: 0 4px 12px @primary-shadow;
    display: flex;
    align-items: center;
    z-index: 2;

    .left {
      display: flex;
      align-items: center;

      .item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px;
        
        .text {
          font-size: 22px;
          color: @text-secondary;
          margin-top: 4px;
        }

        .jixuanico {
          font-size: 45px;
          color: @primary-color;
        }
      }

      .line {
        width: 2px;
        height: 50px;
        background: #dadada;
        margin: 0 10px;
      }
    }

    .mid {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px;

      .text {
        font-size: 30px;
        font-weight: 500;
        color: @text-primary;

        &.num {
          margin: 0 5px;
          color: @primary-color;
        }
      }
    }

    .right {
      padding: 0 30px;
      margin: 0 30px;
      margin-left: 20px;
      color: #fff;
      background: @primary-color;
      font-size: 40px;
      font-weight: 500;
      height: 70px;
      line-height: 70px;
      border-radius: 35px;
      transition: all 0.3s ease;

      &:active {
        transform: scale(0.98);
        background: darken(@primary-color, 5%);
      }
    }
  }

  .wrapper {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    padding: 20px;
    height: 260px;
    background: #fff;
    z-index: 1;
    box-shadow: 0 4px 12px @primary-shadow;
    border-radius: 20px 20px 0 0;
    transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);

    &.active {
      transform: translateY(-105%);
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      height: 65px;
      margin-bottom: 10px;

      .label {
        font-size: 30px;
        color: @text-primary;
        min-width: 180px;
      }

      .bet-number {
        flex: 1;
        margin: 0 16px;
        overflow: auto;
        white-space: nowrap;
        color: @primary-color;
        font-size: 30px;
        font-weight: 500;
        height: 40px;
        line-height: 40px;
        -webkit-overflow-scrolling: touch;
      }

      .amount-wrapper {
        flex: 1;
        display: flex;
        align-items: center;

        .van-field {
          flex: 1;
        }
      }

      .part {
        margin-right: 20px;

        span {
          font-size: 30px;
          color: @text-primary;

          &.number {
            margin: 0 5px;
            color: @primary-color;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.confirm-order-modal {
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 20px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 680px;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 30px;
}

.confirm-order-modal .head {
  position: relative;
  height: 80px;
}

.confirm-order-modal .head .text {
  padding: 0 20px;
  height: 30px;
  line-height: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: @primary-color;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2px;
}

.van-popup--center {
  border-radius: 30px;
}

.confirm-order-modal .list {
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
  flex: 1;
  overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
  color: @primary-color;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
  word-wrap: break-word;
  word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
  line-height: 0px;
  font-size: 25px;
  color: #979799;
}

.confirm-order-modal .list .lise-item {
  color: #ff253f;
}

.confirm-order-modal .sub-bar {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
  min-width: 40%;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 35px;
  font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
  border: 2px solid #979799;
  color: #979799;
  background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
  color: #fff;
  border: 0;
}

.next-number span {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  float: right;
}

.sumValueTwoSides {
  .rectangle {
    &.large {
      background: #fff2f6;
      border-radius: 15px;
      
      .wrapper {
        background: transparent;
        
        .content {
          .name-text {
            color: @primary-color;
            font-size: 48px;
            margin-bottom: 12px;
            font-weight: 500;
          }
          
          .odd-text {
            color: @primary-color;
            font-size: 32px;
            font-weight: 400;
          }
        }
      }
      
      &.active {
        background: @primary-color !important;
        
        .wrapper {
          background: transparent !important;
          
          .name-text,
          .odd-text {
            color: #fff !important;
          }
        }
      }
    }
  }
}

.bottom-bar {
  .bar {
    background: #fff;
    
    .right {
      background: @primary-color;
      color: #fff;
      border-radius: 25px;
      transition: all 0.3s ease;
      
      &:active {
        background: darken(@primary-color, 5%);
      }
    }
  }
  
  .wrapper {
    background: #fff;
    border-radius: 20px 20px 0 0;
    
    .item {
      .bet-number {
        color: @primary-color;
      }
      
      .part {
        .number {
          color: @primary-color;
        }
      }
    }
  }
}

.confirm-order-modal {
  background: #fff;
  border-radius: 20px;
  
  .head {
    .text {
      color: @primary-color;
    }
  }
  
  .list {
    .lise-item {
      .main {
        .bet-name {
          color: @primary-color;
        }
      }
    }
  }
  
  .sub-bar {
    .item {
      &.sub-btn {
        background: @primary-color;
        transition: all 0.3s ease;
        
        &:active {
          background: darken(@primary-color, 5%);
        }
      }
    }
  }
}

.van-popup {
  &--top {
    .wrapper {
      padding: 20px;
      background: #fff;

      .item {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        border-bottom: 1px solid #f5f5f5;
        
        .left {
          flex: 0 0 200px;
          font-size: 30px;
          color: #666;
          
          &.font-weight {
            color: #333;
            font-weight: 500;
          }
        }
        
        .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          
          &.font-weight {
            font-weight: 500;
          }
          
          .kuaisan-ball {
            display: flex;
            align-items: center;
            
            .res-img {
              width: 60px;
              height: 60px;
              margin-right: 15px;
              border-radius: 8px;
              box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
              
              &:last-of-type {
                margin-right: 20px;
              }
            }
            
            .res-des {
              min-width: 80px;
              height: 40px;
              line-height: 40px;
              font-size: 28px;
              color: #ff6b9d;
              padding: 0 12px;
              background: rgba(255, 107, 157, 0.1);
              border-radius: 20px;
              text-align: center;
              
              &.middle {
                margin: 0 8px;
              }
            }
          }
        }

        &:first-child {
          border-bottom: 2px solid #f2f2f2;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.nav-bar {
  background: linear-gradient(to right, #7e5678, #e6c3a1);
  
  :deep(.van-nav-bar__title) {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
  }
  
  :deep(.van-icon) {
    font-size: 48px;
  }
}
</style>
