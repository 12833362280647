<template>
  <div class="container page">
    <van-nav-bar :title="$t('mine.bank.bind.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{ $t('mine.bank.bind.tip') }}</div>
      <van-cell-group>
        <van-field v-model="name" :label="$t('mine.bank.bind.name')" :placeholder="$t('mine.bank.bind.namePlaceholder')" />
        <van-field v-model="bankid" :label="$t('mine.bank.bind.cardNumber')" type="digit" :placeholder="$t('mine.bank.bind.cardNumberPlaceholder')" />
        <van-field v-model="bank" :label="$t('mine.bank.bind.bank')" :placeholder="$t('mine.bank.bind.bankPlaceholder')" />
      </van-cell-group>
      <div class="wrapper" style="display: none;">
        <div class="item">
          <p class="title">{{ $t('mine.bank.bind.payPassword.title') }}</p>
          <van-password-input :value="opw" :length="4" :gutter="10" :focused="oshowKeyboard"
            @focus="oshowKeyboard = true; tshowKeyboard = false;" />
          <van-number-keyboard v-model="opw" :show="oshowKeyboard"
            @input="opw.length !== 3 ? oshowKeyboard = true : tshowKeyboard = true;" @blur="oshowKeyboard = false" />
        </div>
        <div class="item">
          <p class="title">{{ $t('mine.bank.bind.payPassword.confirmTitle') }}</p>
          <van-password-input :value="tpw" :length="4" :gutter="10" :focused="tshowKeyboard"
            @focus="tshowKeyboard = true; oshowKeyboard = false;" />
          <van-number-keyboard v-model="tpw" :show="tshowKeyboard"
            @input="tpw.length !== 3 ? tshowKeyboard = true : tshowKeyboard = false; oshowKeyboard = false"
            @blur="tshowKeyboard = false" />
        </div>
        <!-- <van-button class="sub-btn" type="default" @click="setPayPassword()">제출</van-button> -->
      </div>
      <p>{{ $t('mine.bank.bind.notice') }}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{ $t('mine.bank.bind.button') }}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
          :confirm-button-text="$t('mine.bank.bind.picker.confirm')"
          :cancel-button-text="$t('mine.bank.bind.picker.cancel')"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name:"",
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      bank:"",
      opw: '',
      tpw: '',
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.name === "" || this.name === null || this.name === undefined){
        this.$toast.fail(this.$t('mine.bank.bind.toast.enterName'));
        return false;
      }
      if(this.userInfo.bankid){
        this.$toast(this.$t('mine.bank.bind.toast.enterCardNumber'));
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail(this.$t('mine.bank.bind.toast.selectBank'));
        return false;
      }
      // if (this.opw.length !== 4) {
      //   this.oshowKeyboard = true;
      //   this.$toast(this.$t('mine.bank.bind.toast.enterComplete'));
      //   return false;
      // }
      // if (this.tpw.length !== 4) {
      //   this.tshowKeyboard = true;
      //   this.$toast("전체 입력");
      //   return false;
      // }
      this.$http({
        method: 'post',
        data:{bankid:this.bankid,bank:this.bank,name:this.name, opw: this.opw, tpw: this.tpw},
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
