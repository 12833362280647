<template>
    <div class="container page">
        <van-nav-bar :title="$t('mine.gameRecord.detail.title')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="detail-container">
            <div v-for="item in dataList" :key="item.id" class="detail-item">
                <van-row>
                    <van-col span="12">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.period') }}: 
                        </span>
                        <span class="info">
                            {{item.expect}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.winAmount') }}: 
                        </span>
                        <span class="info">
                            {{item.win_text}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.betAmount') }}: 
                        </span>
                        <span class="info">
                            {{item.money}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.profit') }}: 
                        </span>
                        <span class="info">
                            {{item.is_win===2?0:item.profit}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.betType') }}: 
                        </span>
                        <span class="info">
                            {{item.status_text}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.result') }}: 
                        </span>
                        <span class="info" v-if="item.type=== '大'">
                            {{ $t('mine.gameRecord.detail.type.sexy') }}
                        </span>
                        <span class="info" v-if="item.type=== '小'">
                            {{ $t('mine.gameRecord.detail.type.sweet') }}
                        </span>
                        <span class="info" v-if="item.type=== '单'">
                            {{ $t('mine.gameRecord.detail.type.mature') }}
                        </span>
                        <span class="info" v-if="item.type=== '双'">
                            {{ $t('mine.gameRecord.detail.type.pure') }}
                        </span>
                    </van-col>
                    <van-col span="24">
                        <span class="title-label">
                            {{ $t('mine.gameRecord.detail.time') }}: 
                        </span>
                        <span class="info">
                            {{item.create_time}}
                        </span>
                    </van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataList: []
        };
    },
    methods: {
        back() {
            return window.history.back();
        }
    },
    created() {
        if(this.$route.query.data) {
            this.dataList = JSON.parse(this.$route.query.data);
        }
    }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.detail-container {
    padding: 15px;
    background-color: #f7f8fa;
}
.detail-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}
.title-label {
    color: #646566;
    font-size: 28px;
    margin-right: 10px;
}
.info {
    color: #323233;
    font-size: 28px;
}
.van-col {
    margin-bottom: 10px;
}
</style>